export function bridgeLandingPageJS () {
    document.body.addEventListener('click', function (event) {
        // Check if the clicked element has the class 'request-demo-js'
        if (event.target.classList.contains('request-demo-js')) {
            window.location.href = '/request-demo';
        }

        // Check if the clicked element has the class 'request-trial-js'
        if (event.target.classList.contains('request-trial-js')) {
            window.location.href = '/request-free-trial';
        }
    });

    function isInViewport (element, threshold = 0.7) {
        const rect = element.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height * threshold) >= 0);
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width * threshold) >= 0);

        return (vertInView && horInView);
    }

    // const panelOneImage = document.querySelector('.panel-one-image-js');
    const panelTwoImageOne = document.querySelector('.panel-two-image-one-js');
    const panelTwoImageTwo = document.querySelector('.panel-two-image-two-js');
    const panelThreeImage = document.querySelector('.panel-three-image-js');
    const panelFourImageOne = document.querySelector('.panel-four-image-one-js');
    const panelFourImageTwo = document.querySelector('.panel-four-image-two-js');
    const panelFiveImage = document.querySelector('.panel-five-image-js');
    const panelSixImage = document.querySelector('.panel-six-image-js');

    window.addEventListener('scroll', function () {
        if (isInViewport(panelTwoImageOne)) {
            panelTwoImageOne.classList.add('fade-in');
        }

        if (isInViewport(panelTwoImageTwo)) {
            panelTwoImageTwo.classList.add('fade-in');
        }

        if (isInViewport(panelThreeImage)) {
            panelThreeImage.classList.add('fade-in-and-up');
        }

        if (isInViewport(panelFourImageOne)) {
            panelFourImageOne.classList.add('fade-in');
        }

        if (isInViewport(panelFourImageTwo)) {
            panelFourImageTwo.classList.add('fade-in');
        }

        if (isInViewport(panelFiveImage)) {
            panelFiveImage.classList.add('fade-in-and-up');
        }

        if (isInViewport(panelSixImage)) {
            panelSixImage.classList.add('fade-in-and-up');
        }
    });
}
