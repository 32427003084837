import './sass/main.scss';
import { navJS } from './js/partials/nav';
import { homePageJS } from './js/home-page';
import { signUpPageJS } from './js/sign-up-page';
import { sfccSearchPageJS } from './js/sfcc-search-page';
import { bridgeLandingPageJS } from './js/bridge-landing-page';
import { bridgeHirePageJS } from './js/bridge-hire-page';
import { pricingPageJS } from './js/pricing-page';

document.addEventListener('DOMContentLoaded', function () {
    navJS();

    if (document.querySelector('.home-js')) {
        homePageJS();
    }

    if (document.querySelector('.sign-up-page-js') || document.querySelector('.alpha-sign-up-page-js')) {
        signUpPageJS();
    }

    if (document.querySelector('.sfcc-search-page-js')) {
        sfccSearchPageJS();
    }

    if (document.querySelector('.bridge-logs-page-js')) {
        bridgeLandingPageJS();
    }

    if (document.querySelector('.bridge-hire-page-js')) {
        bridgeHirePageJS();
    }

    if (document.querySelector('.pricing-page-js')) {
        pricingPageJS();
    }
});
