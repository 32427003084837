export function initModals () {
    // Function to close a modal
    function closeModal (modal) {
        modal.style.animation = 'fadeOut 0.5s';
        setTimeout(function () {
            modal.style.display = 'none';
            modal.style.animation = ''; // Reset animation to allow it to play again
        }, 500);
    }

    // Function to open a modal
    function openModal (modalId) {
        const modal = document.getElementById(modalId);
        modal.style.display = 'block';
    }

    // Set up click event on the body, and check if the clicked element is a modal button
    document.body.addEventListener('click', function (event) {
        if (event.target.matches('.modal-btn')) {
            const modalId = event.target.getAttribute('data-modal');
            openModal(modalId);
        }
    });

    // Set up click event for closing modals
    const closeSpans = document.getElementsByClassName('close');
    Array.from(closeSpans).forEach(function (span) {
        span.onclick = function () {
            const modal = this.closest('.modal');
            closeModal(modal);
        };
    });

    // Close modal when clicking outside of the modal content
    window.onclick = function (event) {
        if (event.target.classList.contains('modal')) {
            closeModal(event.target);
        }
    };
}
