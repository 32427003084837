export function initializeSocket (bridgeToken) {
    // Creating the base WebSocket URL
    const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl = `${wsProtocol}//${window.location.hostname}:${window.location.port}/questions/sfcc-search`;

    // Appending the token as a query parameter
    const socketUrl = `${wsUrl}?token=${bridgeToken}`;

    // Creating the WebSocket connection
    const socket = new WebSocket(socketUrl);

    return socket;
}
