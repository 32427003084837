export function navJS () {
    const dropdowns = document.querySelectorAll('.dropdown');

    dropdowns.forEach(dropdown => {
        dropdown.addEventListener('mouseenter', () => {
            const menu = dropdown.querySelector('.dropdown-menu');
            menu.style.display = 'block';
            setTimeout(() => {
                menu.style.opacity = '1';
                menu.style.visibility = 'visible';
            }, 10);
        });

        dropdown.addEventListener('mouseleave', () => {
            const menu = dropdown.querySelector('.dropdown-menu');
            menu.style.opacity = '0';
            menu.style.visibility = 'hidden';
            setTimeout(() => {
                menu.style.display = 'none';
            }, 300);
        });
    });

    function logout () {
        fetch('/login/logout').then(() => {
            // Redirect to the home page or the login page
            window.location.href = '/';
        }).catch(error => {
            console.error('Error:', error);
        });
    }

    const logoutLink = document.querySelector('.logout-link-js');

    if (logoutLink) {
        logoutLink.addEventListener('click', () => {
            logout();
        });
    }

    const header = document.getElementById('header');
    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            header.classList.add('fixed-header');
        } else {
            header.classList.remove('fixed-header');
        }
    });

    const menuIcon = document.getElementById('menu-icon');
    const closeIcon = document.getElementById('close-icon');
    const navLinks = document.getElementById('nav-links');
    const bodyTag = document.querySelector('body');

    menuIcon.addEventListener('click', () => {
        bodyTag.classList.toggle('no-overflow');
        navLinks.classList.toggle('nav-active');
    });

    closeIcon.addEventListener('click', () => {
        bodyTag.classList.toggle('no-overflow');
        navLinks.classList.remove('nav-active');
    });

    document.querySelector('.contact-nav-btn-js').addEventListener('click', () => {
        window.location.href = '/talk-to-an-expert';
    });
}
