export function newChat () {
    // 1. Select the conversation-id-js class and clear the value of the element
    const conversationIdElement = document.querySelector('.conversation-id-js');
    if (conversationIdElement) {
        conversationIdElement.value = '';
    }

    // 2. Select the element with response-block-js class and empty the element
    const responseBlockElement = document.querySelector('.response-block-js');
    if (responseBlockElement) {
        responseBlockElement.innerHTML = '';
    }

    // 3. Remove the chat-border element from the response-block-js element
    if (responseBlockElement) {
        const chatBorderElement = responseBlockElement.querySelector('.chat-border');
        if (chatBorderElement) {
            chatBorderElement.remove();
        }
    }

    // 4. Clears the textarea element from the element that matches the question-textarea class
    const questionTextareaElement = document.querySelector('.question-textarea');
    if (questionTextareaElement) {
        questionTextareaElement.value = '';
    }

    // 5. Removes the show class from the element that matches the sfcc-share-container-js class
    const sfccShareContainerElement = document.querySelector('.sfcc-share-container-js');
    if (sfccShareContainerElement) {
        sfccShareContainerElement.classList.remove('show');
    }

    // 6. Remove the show class from small-notification-js
    const smallNotificationElement = document.querySelector('.small-notification-js');
    if (smallNotificationElement) {
        smallNotificationElement.classList.remove('show');
    }

    // 7. Gradual scroll to the element that matches the sfcc-search-js class
    const sfccSearchElement = document.querySelector('.sfcc-search-header-js');
    if (sfccSearchElement) {
        sfccSearchElement.scrollIntoView({ behavior: 'smooth' });
    }
}
