async function homePageJS () {
    if (window.innerWidth > 1024) {
        const version = document.querySelector('.main-header-js').dataset.version;
        const videoContainer = document.getElementById('videoContainer');
        const video = document.createElement('video');
        video.autoplay = true;
        video.muted = true;
        video.className = 'home__choice-figure__video home-video-js';

        const source = document.createElement('source');
        source.src = `/public/${version}/img/home/demo-video.mp4`;
        source.type = 'video/mp4';

        video.appendChild(source);
        videoContainer.appendChild(video);

        // Optional: remove placeholder image
        const placeholderImage = document.getElementById('placeholderImage');
        if (placeholderImage) {
            placeholderImage.style.display = 'none';
        }

        document.querySelector('.home-video-js').addEventListener('ended', function () {
            video.style.display = 'none';
            placeholderImage.style.display = 'block';
        });
    }

    const hireButtons = document.querySelectorAll('.home-hire-bridge-gpt-js');

    // Add click event listener to each element
    hireButtons.forEach(function (hireButton) {
        hireButton.addEventListener('click', function () {
            // Navigate to /hire-bridgegpt
            window.location.href = '/hire-bridgegpt';
        });
    });
}

module.exports = {
    homePageJS
};
