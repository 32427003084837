import markdownToHtml from '../partials/remark';
import { highlightCode } from '../partials/highlight.js';

export function formatQuestion (question) {
    const trimmedQuestion = question.trim();
    const capitalizedQuestion = trimmedQuestion.charAt(0).toUpperCase() + trimmedQuestion.slice(1);
    return capitalizedQuestion;
}

export function replaceNewlinesWithBreaks (text) {
    return text.replace(/\n/g, '\n<br/>');
}

const modelPlainName = {
    'gpt-4o': 'GPT-4 Omni',
    'gpt-4-turbo-preview': 'GPT-4',
    'gpt-4o-mini': 'GPT-4 Mini',
    'gpt-3.5-turbo': 'GPT-3.5',
    'claude-3-5-sonnet-20240620': 'Claude Sonnet 3.5',
    'claude-3-haiku-20240307': 'Claude Haiku',
    'gemini-1.5-pro-latest': 'Gemini 1.5',
    'gemini-1.0-pro-latest': 'Gemini 1.0',
    'llama3-70b-8192': 'LLaMA 3',
    'mixtral-8x7b-32768': 'Mixtral'
};

export function generateResponseElement (index, model, content = '') {
    const gptResponseBlock = `<div class="prior-response__left-panel response-left-block-${index}-js">
        <p data-index="${index}" 
            class="prior-response response-js response-${index}-js">
            <strong>${modelPlainName[model]} Response</strong>: 
            <span class="response-text-js converstation-turn-js response-text-${index}-js">${content}</span>
        </p>
    </div>`;
    return `<div class="prior-response-container response-container-${index}-js">
                ${model !== 'search-only' ? gptResponseBlock : ''}
                <div class="prior-response__right-panel response-right-block-${index}-js"></div>
            </div>`;
}

export function generateQuestionElement (index, question) {
    return `<p data-index="${index}" 
                class="prior-question question-js question-${index}-js">
                <strong>You</strong>: 
                <span class="question-text-js converstation-turn-js question-text-${index}-js">${question}</span>
            </p>`;
}

export function generateResponseButtons (messageId) {
    return `<div class="response-button-container">
            <button class="good-response-js user-vote-js btn vote-button good-response" data-responsenum="${messageId}">
                Yes
            </button>
            <button class="bad-response-js user-vote-js btn vote-button bad-response" data-responsenum="${messageId}">
                No
            </button>
    </div>`;
}

export function generateReferencesElement (index, dataPayload) {
    const eleSearch = document.querySelector(`.response-right-block-${index}-js`);

    if (dataPayload) {
        eleSearch.insertAdjacentHTML('beforeend', `<div class="references"><p class="references-text"><strong>References:</strong></p><ol class='results-list results-list-${index}-js'></ol><div>`);
        const listElement = document.querySelector(`.results-list-${index}-js`);
        dataPayload.forEach((urlObject, idx) => {
            listElement.insertAdjacentHTML('beforeend', `<li><a class="reference-link" href="${urlObject.url}" target="_blank">${urlObject.title}</a>${urlObject.url.includes('sfcclearning.com') ? ' <small>(Legacy Docs)</small>' : ''}</li>`);
        });
    }
}

export function formatResponse (index) {
    const ele = document.querySelector(`.response-text-${index}-js`);

    if (ele) {
        markdownToHtml(ele.textContent).then(formattedHTML => {
            const version = document.querySelector('.main-header-js').dataset.version;
            const highlightedHtml = highlightCode(formattedHTML);
            ele.innerHTML = highlightedHtml;

            const codeElements = document.querySelectorAll('pre code');
            if (codeElements.length > 0) {
                ele.querySelectorAll('pre').forEach(pre => {
                    pre.insertAdjacentHTML('afterbegin', `<a class="click-to-copy"><img class="click-to-copy-js" width=28px" height="28px" src="/public/${version}/img/icons/copy.webp" /></a>`);
                });
            }
        });
    }
}

export function generateConfidenceScore (score) {
    return `<p class="confidence-score">Response confidence: <a class="explain-confidence-score-js modal-btn" data-modal="confidence-explainer" href="javascript:;">${score}</a></p>`;
}

export function createAutoScroll (targetSelector) {
    let isAutoScrolling = false;
    let observer;

    function startAutoScroll () {
        if (isAutoScrolling) return;

        isAutoScrolling = true;
        const targetElement = document.querySelector(targetSelector);

        if (!targetElement) {
            console.error('Target element not found');
            return;
        }

        observer = new MutationObserver(() => {
            if (!isAutoScrolling) return;
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        });

        observer.observe(targetElement, { childList: true, characterData: true, subtree: true });

        // Initial scroll
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    function stopAutoScroll () {
        isAutoScrolling = false;
        if (observer) {
            observer.disconnect();
        }
    }

    // Event listener to stop auto-scrolling on user interaction
    function handleUserInteraction () {
        if (isAutoScrolling) {
            stopAutoScroll();
        }
    }

    document.addEventListener('wheel', handleUserInteraction);
    document.addEventListener('touchmove', handleUserInteraction);

    return {
        start: startAutoScroll,
        stop: stopAutoScroll
    };
}
