import { LangfuseWeb } from 'langfuse';

export function vote (traceId, value) {
    if (traceId) {
        const langfuseWeb = new LangfuseWeb({
            publicKey: 'pk-lf-c7ef9e1f-dc4f-415a-987f-53acc8957f17',
            baseUrl: 'https://us.cloud.langfuse.com'
        });

        // Handle user feedback
        langfuseWeb.score({
            traceId,
            name: 'user_feedback',
            value
        });
    }
}
