/* eslint-disable no-case-declarations */
import { initializeSocket } from './library/socket-management';
import { initModals } from './partials/modal';
import {
    initPriorConversations,
    createConversations,
    fetchPriorConversation,
    createConversation
} from './partials/prior-conversations';
import { newChat } from './partials/new-chat';

import {
    formatQuestion,
    replaceNewlinesWithBreaks,
    generateResponseElement,
    generateQuestionElement,
    generateConfidenceScore,
    generateReferencesElement,
    formatResponse,
    createAutoScroll
} from './library/question-management';

import {
    getToken
} from './library/token-management';

import {
    vote
} from './library/voting';

function callChatGPT (socket) {
    const questionElement = document.querySelector('.question-js');
    const modelElement = document.querySelector('.model-select-js');
    const subjectElement = document.querySelector('.subject-select-js');
    const conversationIdElement = document.querySelector('.conversation-id-js');
    const cachingElement = document.querySelector('.caching-select-js');
    const fileInput = document.querySelector('.file-input-js');
    const sdkElement = document.querySelector('.sdk-select-js');

    const question = formatQuestion(questionElement.value);
    const model = modelElement.value;
    const conversationId = conversationIdElement.value;
    const caching = cachingElement.value;
    let subject = subjectElement.value;
    let filename = '';

    if (sdkElement) {
        const sdkElementValue = sdkElement.value;

        if (sdkElementValue !== 'no-sdk') {
            subject = `${subject}-${sdkElement.value}`;
        }
    }

    if (fileInput.files.length > 0) {
        filename = fileInput.files[0].name; // Only the name of the file
    }

    const messagePackage = JSON.stringify({
        question,
        model,
        subject,
        conversationId,
        caching,
        filename
    });

    socket.send(messagePackage);
}

function updateQueryParam (uri, key, value) {
    // Remove the hash part before operating on the URI
    const i = uri.indexOf('#');
    const hash = i === -1 ? '' : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';

    if (!value) {
        // Remove key-value pair if value is empty
        uri = uri.replace(new RegExp('([?&]?)' + key + '=[^&]*', 'i'), '');
        if (uri.slice(-1) === '?') {
            uri = uri.slice(0, -1);
        }
    } else if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        uri = uri + separator + key + '=' + value;
    }
    return uri + hash; // Finally append the hash as well
}

async function uploadFile (token) {
    // Find the file input element
    const fileInput = document.querySelector('.file-input-js');
    const questionErrorMessage = document.querySelector('.question-error-message-js');

    if (fileInput.files.length === 0) {
        questionErrorMessage.textContent = 'Please select a file to upload.';
        questionErrorMessage.classList.add('show');
        return;
    }

    const file = fileInput.files[0];

    // Validate the file type
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
        questionErrorMessage.textContent = 'Invalid file type. Please select an image (JPG, PNG, GIF). If you have txt, json, pdf, or xml please include the relevant parts in the prompt.';
        questionErrorMessage.classList.add('show');
        return;
    }

    const maxFileSize = 5 * 1024 * 1024;
    if (file.size > maxFileSize) {
        questionErrorMessage.textContent = 'The maximum file size is 5MB. Please compress your image.';
        questionErrorMessage.classList.add('show');
        return;
    }

    // Create a FormData object and append the file
    const formData = new FormData();
    const selectElement = document.querySelector('.subject-select-js');
    formData.append('file', file);
    formData.append('subject', selectElement.value);

    try {
        // Send the file to the server using fetch
        const response = await fetch('/questions/upload-file', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}` // Assuming you're using Bearer token for authorization
            },
            body: formData
        });

        const responseJSON = await response.json();
        const responseMessage = responseJSON.message;
        // Check if the file was uploaded successfully
        if (response.ok) {
            questionErrorMessage.classList.remove('show'); // Hide error message on success
            document.querySelector('.small-notification-js').classList.add('done');
            document.querySelector('.small-notification-js').textContent = responseMessage;
        } else if (responseMessage) {
            document.querySelector('.small-notification-js').classList.remove('show');
            questionErrorMessage.textContent = responseMessage;
            questionErrorMessage.classList.add('show');
        } else {
            document.querySelector('.small-notification-js').classList.remove('show');
            questionErrorMessage.textContent = 'Failed to upload file. Server responded with an error.';
            questionErrorMessage.classList.add('show');
        }
    } catch (error) {
        questionErrorMessage.textContent = 'An error occurred while uploading the file. Please try again later.';
        questionErrorMessage.classList.add('show');
    }
}

function appendSocialLinks (containerEle, shareText, url) {
    if (containerEle) {
        const shareEncodedText = encodeURIComponent(shareText);
        const urlEncoded = encodeURIComponent(url);
        const hashtags = 'sfcc,genai'; // Customize your hashtags

        // Fetching image sources
        const twitterImgSrc = document.querySelector('.twitter-img-js').src;
        const linkedInImgSrc = document.querySelector('.linkedin-img-js').src;

        // Twitter link setup with image
        const twitterLink = document.createElement('a');
        twitterLink.href = `https://twitter.com/intent/tweet?text=${shareEncodedText}&url=${urlEncoded}&hashtags=${hashtags}`;
        twitterLink.id = 'shareToTwitter';
        twitterLink.className = 'share-link share-link-js';
        twitterLink.target = '_blank';
        twitterLink.title = "Share to 'X'";
        const twitterImg = document.createElement('img');
        twitterImg.src = twitterImgSrc;
        twitterLink.appendChild(twitterImg);

        // LinkedIn link setup with image
        const linkedInLink = document.createElement('a');
        linkedInLink.href = `https://www.linkedin.com/sharing/share-offsite/?url=${urlEncoded}&title=${encodeURIComponent('AI Powered search for Saleforce Commerce Cloud B2C!')}`;
        linkedInLink.id = 'shareToLinkedIn';
        linkedInLink.className = 'share-link share-link-js';
        linkedInLink.target = '_blank';
        linkedInLink.title = 'Share to LinkedIn';
        const linkedInImg = document.createElement('img');
        linkedInImg.src = linkedInImgSrc;
        linkedInLink.appendChild(linkedInImg);

        // Append to container
        containerEle.appendChild(linkedInLink);
        containerEle.appendChild(document.createTextNode('   ')); // Optional: Add a separator
        containerEle.appendChild(twitterLink);
    }
}

function spawnSocialSharing () {
    const shareContainer = document.querySelector('.sfcc-share-container-js');
    shareContainer.classList.add('show');
}

export async function sfccSearchPageJS () {
    initModals();
    initPriorConversations();

    // Global elements
    const spinnerElement = document.querySelector('.bridgegpt-spinner-js');
    const errorMessageElement = document.querySelector('.question-error-message-js');
    const warningMessageElement = document.querySelector('.question-warning-message-js');
    const textAreaElement = document.querySelector('#dynamic-textarea');

    const optionDescriptionKey = {
        'gpt-4o': 'The newest AI model from Open.ai. Fast and intelligent.',
        'gpt-4-turbo-preview': 'The newest AI model from Open.ai. GPT-4 Turbo is fast and intelligent.',
        'gpt-4o-mini': 'GPT-4 mini is a fast and reliable workhorse model. It is excellent for simpler tasks.',
        'gpt-3.5-turbo': 'GPT-3.5 is a reliable and fast workhorse model. It is excellent for simpler tasks.',
        'claude-3-opus-20240229': 'Considered the smartest AI by some professionals. However, it\'s more pricey, so you burn through usage caps faster by using it.',
        'claude-3-5-sonnet-20240620': 'An intelligent and concise AI from Anthropic',
        'claude-3-haiku-20240307': 'An inexpensive workhorse model from Anthropic. Does not trigger usage caps.',
        'gemini-1.5-pro-latest': 'The latest and smartest Google AI.',
        'gemini-1.0-pro-latest': 'Gemini 1.0 is not very bright, but it\'s fast and cheap. No usage caps.',
        'search-only': 'Search through the docs without an AI answer'
    };

    document.body.addEventListener('click', function (event) {
        if (event.target.id === 'shareToX') {
            event.preventDefault();
            const shareUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(document.URL)}`;
            window.open(shareUrl, '_blank', 'width=550,height=420');
        } else if (event.target.id === 'shareToLinkedIn') {
            event.preventDefault();
            const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(document.URL)}`;
            window.open(shareUrl, '_blank', 'width=550,height=420');
        }
    });

    document.querySelector('.yes-helpful-js').addEventListener('click', (e) => {
        const messageId = e.target.dataset.responsenum;
        vote(messageId, 1);

        document.querySelector('.helpful-answer-block-js').classList.remove('show');
        document.querySelector('.share-block-js').classList.add('show');

        const shareContainer = document.querySelector('.sfcc-share-container-js');
        const urlWithoutParams = window.location.protocol + '//' + window.location.hostname + window.location.pathname;
        appendSocialLinks(shareContainer, 'Check it out, Gen AI powered docs for Salesforce Commerce Cloud: ', urlWithoutParams);
    });

    document.querySelector('.no-helpful-js').addEventListener('click', (e) => {
        const messageId = e.target.dataset.responsenum;
        vote(messageId, 0);

        document.querySelector('.helpful-answer-block-js').classList.remove('show');
        document.querySelector('.no-block-js').classList.add('show');
    });

    document.querySelectorAll('.model-select-js').forEach(select => {
        select.addEventListener('change', (e) => {
            const optionDescription = optionDescriptionKey[e.target.value];
            if (optionDescription) {
                const explainerElement = document.querySelector('.sfcc-search-explainer-js');
                const explainerElementText = document.querySelector('.sfcc-search-explainer-js p');
                explainerElementText.innerHTML = optionDescription;
                explainerElement.classList.add('show');
            }
        });
    });

    document.querySelectorAll('.param-select-js').forEach(select => {
        select.addEventListener('change', (e) => {
            const currentValue = e.target.value;
            const param = e.target.getAttribute('data-paramname');
            const currentUrl = window.location.href;
            const newUrl = updateQueryParam(currentUrl, param, currentValue);
            window.history.pushState({}, '', newUrl);
        });
    });

    document.body.addEventListener('click', (e) => {
        if (e.target.matches('.click-to-copy-js')) {
            const clickLink = e.target;
            const version = document.querySelector('.main-header-js').dataset.version;
            const codeBlock = clickLink.closest('pre').querySelector('code');
            navigator.clipboard.writeText(codeBlock.textContent);
            clickLink.setAttribute('src', `/public/${version}/img/icons/checkmark.webp`);

            window.setTimeout(() => {
                clickLink.setAttribute('src', `/public/${version}/img/icons/copy.webp`);
            }, 1500);
        }
    });

    document.querySelectorAll('.question-js').forEach(input => {
        input.addEventListener('input', () => {
            errorMessageElement.classList.remove('show');
            warningMessageElement.classList.remove('show');
        });
    });

    document.querySelector('.file-input-image-js').addEventListener('click', (e) => {
        document.querySelector('.file-input-js').click();
    });

    function resizeTextarea (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight - 6}px`;
    }

    textAreaElement.addEventListener('input', function () {
        resizeTextarea(this);

        const textLength = this.value.length;
        if (warningMessageElement) {
            warningMessageElement.classList.remove('show');
        }

        if (errorMessageElement) {
            errorMessageElement.classList.remove('show');
        }

        if (textLength >= 2000) {
            errorMessageElement.classList.add('show');
            errorMessageElement.textContent = 'Sorry, you have exceeded the character limit of 2000 characters.';
        } else if (textLength >= 1500) {
            warningMessageElement.style.display = 'block';
            warningMessageElement.textContent = `You have ${2000 - textLength} characters remaining.`;
        }
    });

    (async function () {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const model = params.get('model');
        const subject = params.get('subject');
        const caching = params.get('caching');
        const sdk = params.get('sdk');
        const conversationid = params.get('conversationid');

        const modelSelect = document.querySelector('.model-select-js');
        const subjectSelect = document.querySelector('.subject-select-js');
        const cachingSelect = document.querySelector('.caching-select-js');
        const sdkSelect = document.querySelector('.sdk-select-js');

        if (sdk && sdkSelect) {
            sdkSelect.value = sdk;
        }

        if (model && modelSelect) {
            modelSelect.value = model;
        }

        if (subject && subjectSelect) {
            subjectSelect.value = subject;
        }

        if (caching && cachingSelect) {
            cachingSelect.value = caching;
        }

        if (conversationid) {
            // Get the current URL
            const url = window.location.href;
            // Create a URL object
            const urlObj = new URL(url);
            // Get the query parameters
            const params = new URLSearchParams(urlObj.search);
            // Extract the accesskey
            const accessKey = params.get('accesskey');

            const conversationData = await fetchPriorConversation(conversationid, accessKey);

            if (conversationData.prior_conversations && conversationData.prior_conversations.length > 0) {
                createConversation(conversationData.prior_conversations);
            }
        }
    })();

    document.querySelector('.question-js').addEventListener('keydown', function (event) {
        // Check if the pressed key is 'Enter'
        if (event.key === 'Enter') {
            // If 'Shift' is also held down, just create a new line
            if (event.shiftKey) {
                return;
            }

            // Otherwise, prevent default 'Enter' behavior (i.e., creating a new line and submit the form
            event.preventDefault();

            // Select the button element
            const submitButton = document.querySelector('.search-button-js');

            // Trigger a click on the button
            submitButton.click();
        }
    });

    function initializeSocketListeners (socket) {
        let responseText;
        let index;
        let autoScroll;

        socket.onclose = function (event) {
            connectionOpen = false;

            if (event.wasClean) {
                console.log(`[close] Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
            } else {
                console.log('[close] Connection died');
                spinnerElement.style.display = 'none';
                warningMessageElement.classList.remove('show');
                errorMessageElement.classList.add('show');
                errorMessageElement.textContent = 'We experienced a server error. Our apologies, please try again later.';
            }
        };

        socket.onerror = function (error) {
            console.log(`[error] ${error.message}`);
            errorMessageElement.classList.add('show');
            errorMessageElement.textContent = 'Connection error. Please refresh the page.';
        };

        socket.onmessage = function (event) {
            let eventData;
            try {
                eventData = JSON.parse(event.data);
            } catch (e) {
                console.error('Could not parse event data:', e);
                return;
            }

            if (!index && eventData.payload && typeof eventData.payload.index !== 'undefined') {
                index = eventData.payload.index;
            }

            switch (eventData.type) {
            case 'responseBegin':
                // Notify user the response has begun and update the UI
                showSpinner('Agent is thinking');
                const currentQuestion = formatQuestion(document.querySelector('.question-js').value);
                const { conversationId } = eventData.payload;
                document.querySelector('.response-block-js').insertAdjacentHTML('afterbegin', generateQuestionElement(index, currentQuestion));
                if (conversationId) {
                    document.querySelector('.conversation-id-js').value = conversationId;
                }
                break;
            case 'imageReview':
                // Notify user the system is working to review their image
                showSpinner('Reviewing your image');
                break;
            case 'waitOnLlama':
                // Notify user that Llama3 answers seem slow
                showSpinner('Llama3 Answers come down all at once. Please wait');
                break;
            case 'searchResults':
                // Show the 'References' section for retrieved documents. Occurs before the answer begins
                const { model, dataPayload, confidenceRating } = eventData.payload;

                showSpinner('Writing an answer');
                document.querySelector('.response-block-js').insertAdjacentHTML('afterbegin', generateResponseElement(index, model));

                generateReferencesElement(index, dataPayload);

                if (model === 'search-only') {
                    document.querySelector(`.response-right-block-${index}-js`).classList.add('full-width');
                }

                responseText = document.querySelector(`.response-text-${index}-js`);
                document.querySelector(`.response-left-block-${index}-js`).insertAdjacentHTML('afterbegin', generateConfidenceScore(confidenceRating));
                autoScroll = createAutoScroll('.response-block-js .response-js');
                autoScroll.start();
                break;
            case 'stopThinkingAnimation':
                // Stops the spinner
                spinnerElement.style.display = 'none';
                break;
            case 'chatResponse':
                // Sends down a chunk of a response.
                const { text } = eventData.payload;
                responseText.insertAdjacentHTML('beforeend', replaceNewlinesWithBreaks(text));
                break;
            case 'done':
                document.querySelector('.question-js').value = '';
                document.querySelector('.question-js').style.height = 'auto';
                document.querySelector('.sfcc-search-explainer-js').classList.remove('show');
                spinnerElement.style.display = 'none';

                formatResponse(index);

                spawnSocialSharing();

                autoScroll.stop();

                break;
            case 'error':
                const { errorMessage, isLocal } = eventData.payload;
                spinnerElement.style.display = 'none';
                errorMessageElement.classList.add('show');
                errorMessageElement.textContent = errorMessage;

                if (isLocal) {
                    console.log('ERR: ', eventData);
                }
                break;
            case 'messageComplete':
                const { currentLimit, overLimitWarning, traceId } = eventData.payload;

                if (traceId) {
                    document.querySelector('.yes-helpful-js').dataset.responsenum = traceId;
                    document.querySelector('.no-helpful-js').dataset.responsenum = traceId;
                }

                document.querySelector(`.response-container-${index}-js`).classList.add(`response-${index}-complete-js`);
                // document.querySelector(`.response-container-${index}-js`).insertAdjacentHTML('afterbegin', generateResponseButtons(assistantMessageId));

                if (overLimitWarning) {
                    const modelSelect = document.querySelector('.sfcc-search__body-form__model-select.model-select-js.param-select-js');
                    warningMessageElement.classList.add('show');
                    warningMessageElement.textContent = overLimitWarning;
                    modelSelect.value = 'gpt-3.5-turbo';
                } else if (currentLimit || currentLimit === 0) {
                    const message = `Premium search tokens left: <a class="premium-search-token-js premium-search-btn modal-btn" data-modal="premium-search-token-explainer" href="javascript:;">${currentLimit}</a>`;
                    const notificationElement = document.querySelector('.small-notification-js');
                    notificationElement.classList.add('show');
                    notificationElement.innerHTML = message;
                }

                // TODO add in a new conversation without hammering the backend.
                // window.setTimeout(async () => {
                //     const navbar = document.getElementById('navbar');
                //     const isLoggedIn = navbar.dataset.loggedIn === 'true';

                //     if (isLoggedIn) {
                //         await createConversations();
                //     }
                // }, 500);

                break;
            default:
                console.log('Unknown event type:', eventData.type);
            }
        };
    }

    // Create a WebSocket connection
    const token = await getToken();
    let socket = initializeSocket(token);
    let connectionOpen = true;
    initializeSocketListeners(socket);

    function sendMessage () {
        callChatGPT(socket);
    }

    function showSpinner (text) {
        spinnerElement.style.display = 'flex';
        spinnerElement.textContent = text;
    }

    function resetPage () {
        // document.querySelector('.change-settings-js').classList.remove('show');
        document.querySelector('.response-block').classList.add('chat-border');
        document.querySelector('.sfcc-search-header-js').style.display = 'none';
        document.querySelector('.sfcc-share-container-js').classList.remove('hidden');
        spinnerElement.style.display = 'flex';
        document.querySelector('.sfcc-search-explainer-js').classList.remove('show');
        document.querySelector('.advanced-controls-js').classList.remove('show');
        document.querySelector('.small-notification-js').classList.remove('show');
        document.querySelector('.sfcc-share-container-js').classList.remove('show');
        document.querySelector('.helpful-answer-block-js').classList.add('show');
        document.querySelector('.share-block-js').classList.remove('show');
        document.querySelector('.no-block-js').classList.remove('show');
    }

    const fileInput = document.querySelector('.file-input-js');

    fileInput.addEventListener('change', function () {
        if (this.files.length > 0) {
            const file = this.files[0];
            document.querySelector('.small-notification-js').classList.add('show');
            document.querySelector('.small-notification-js').textContent = `Now processing: ${file.name}`;
            uploadFile(token);
        }
    });

    document.querySelector('.sfcc-search-js').addEventListener('submit', async function (event) {
        event.preventDefault();

        if (!connectionOpen) {
            const token = await getToken();
            socket = initializeSocket(token);
            initializeSocketListeners(socket);
            window.setTimeout(() => {
                sendMessage();
            }, 100);
        } else {
            sendMessage();
        }

        resetPage();
        showSpinner('Response beginning');
    });

    document.querySelector('.new-chat-js').addEventListener('click', () => {
        newChat();
    });

    // Select the elements
    const signpost = document.querySelector('.signpost-js');
    const searchSubmitContainer = document.querySelector('.search-submit-container-js');

    // Create the IntersectionObserver
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                // Element is out of view, add the class
                searchSubmitContainer.classList.add('fixed-question-form');
                resizeTextarea(textAreaElement);
            } else {
                // Element is in view, remove the class
                searchSubmitContainer.classList.remove('fixed-question-form');
                resizeTextarea(textAreaElement);
            }
        });
    }, { threshold: [0] }); // Trigger the callback when the element is completely out of view

    // Observe the signpost element
    observer.observe(signpost);
}
