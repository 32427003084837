import { newChat } from './new-chat';
import {
    generateQuestionElement,
    generateResponseElement,
    generateReferencesElement,
    formatResponse
} from '../library/question-management';

let version;
window.setTimeout(() => {
    version = document.querySelector('.main-header-js').dataset.version;
}, 50);

function removePriorConversations () {
    document.querySelector('.prior-conversation-nav-js').classList.remove('flyout');
    document.querySelector('.overlay-js').classList.remove('prior-conversations__overlay');
}

export function createConversation (conversationJSON) {
    let index = 0;

    conversationJSON.forEach((conversationTurnJSON) => {
        // update iterative loop to get an index
        const speaker = conversationTurnJSON.role;
        const content = conversationTurnJSON.content;

        if (speaker === 'assistant') {
            const model = conversationTurnJSON.model;
            const dataPayloadStr = conversationTurnJSON.data_payload;
            const dataPayload = JSON.parse(dataPayloadStr);

            document.querySelector('.response-block-js').insertAdjacentHTML('afterbegin', generateResponseElement(index, model, content));
            generateReferencesElement(index, dataPayload);
            formatResponse(index);

            index = index + 1;
        } else {
            document.querySelector('.response-block-js').insertAdjacentHTML('afterbegin', generateQuestionElement(index, content));

            if (index === 0) {
                const conversationId = conversationTurnJSON.conversation_id;

                if (conversationId) {
                    document.querySelector('.conversation-id-js').value = conversationId;
                }
            }
        }
    });
}

async function getPriorConversations () {
    try {
        const response = await fetch('/conversations/prior-conversations', {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.prior_conversations;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

async function deleteConversation (conversationId) {
    const url = `/conversations/delete-prior-conversation?conversation_id=${conversationId}`;

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            const element = document.querySelector(`.prior-conversation-list-item-js[data-conversation-id="${conversationId}"]`);
            if (element) {
                element.remove();
            }
            document.querySelector('.conversation-tooltip-js').classList.remove('show');
        } else if (response.status === 401) {
            console.error('Unauthorized');
        } else if (response.status === 404) {
            console.error('Conversation not found');
        } else {
            console.error('An error occurred');
        }
    } catch (error) {
        console.error('Network error:', error);
    }
}

// Function to fetch prior conversation
export async function fetchPriorConversation (conversationId, accessKey) {
    const url = `/conversations/prior-conversation?conversation_id=${conversationId}&accesskey=${accessKey}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            // Include any other headers you might need, such as authentication headers
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
    }
}

export async function createConversations (priorConversationsArg = null) {
    let priorConversations = priorConversationsArg;

    if (!priorConversations) {
        priorConversations = await getPriorConversations();
    }

    // Get the container element where new elements will be appended
    const conversationContainer = document.getElementById('conversation-container');

    conversationContainer.innerHTML = '';

    let previousDate = null;

    // Iterate over each array in the arrayOfArrays
    priorConversations.forEach((subArray, index) => {
        // Get the last item in the current sub-array
        const lastItem = subArray[subArray.length - 1];

        // Generate local date
        const dateObject = new Date(lastItem.timestamp);
        const userLocale = navigator.language || navigator.userLanguage;
        const formattedDate = new Intl.DateTimeFormat(userLocale, { year: 'numeric', month: 'long', day: 'numeric' }).format(dateObject);

        // Check if the date has changed or it's the first item
        if (previousDate !== formattedDate) {
            // Create the date text block for the current date
            const dateTextBlock = document.createElement('p');
            dateTextBlock.textContent = formattedDate;
            dateTextBlock.classList.add('prior-conversations__list--date-block');

            // Append the date text block to the conversation container
            conversationContainer.append(dateTextBlock);

            // Update the previousDate to the current date
            previousDate = formattedDate;
        }

        // Create a new HTML element using a template literal
        const newElementContainer = document.createElement('li');
        newElementContainer.classList.add('prior-conversations__list-item');
        newElementContainer.classList.add('prior-conversation-list-item-js');
        newElementContainer.setAttribute('data-conversation-id', lastItem.conversation_id);
        newElementContainer.innerHTML = `
            <a data-conversation-id="${lastItem.conversation_id}" 
                data-conversation-date="${formattedDate}" 
                href="javascript:;" 
                class="prior-conversations__list--link prior-conversation-js">
                <span class="prior-conversations__list--link-text">${lastItem.content}</span>
                <a title="Options" class="conversation-options-js prior-conversations__list--link-options" data-conversation-id="${lastItem.conversation_id}">
                    <img 
                        class="prior-conversations__list--link-options-img" 
                        src="/public/${version}/img/icons/menu-dots.webp" 
                        alt="Options" 
                        width="25px"
                        height="25px" />
                </a>
            </a>
        `;

        // Append the new element to the container
        conversationContainer.append(newElementContainer);
    });
}

export function initPriorConversations () {
    document.querySelector('.prior-conversations-trigger-js').addEventListener('click', () => {
        document.querySelector('.prior-conversation-nav-js').classList.add('flyout');
        document.querySelector('.overlay-js').classList.add('prior-conversations__overlay');
    });

    document.querySelector('.overlay-js').addEventListener('click', () => {
        removePriorConversations();
    });

    document.querySelector('.prior-conversations-close-js').addEventListener('click', () => {
        removePriorConversations();
    });

    const conversationSearchBar = document.querySelector('.conversation-search-query-js');
    if (conversationSearchBar) {
        conversationSearchBar.addEventListener('keydown', function (event) {
            // Check if the pressed key is 'Enter'
            if (event.key === 'Enter') {
                // If 'Shift' is also held down, just create a new line
                if (event.shiftKey) {
                    return;
                }

                // Otherwise, prevent default 'Enter' behavior (i.e., creating a new line and submit the form
                event.preventDefault();

                // Select the button element
                const submitButton = document.querySelector('.conversation-search-button-js');

                // Trigger a click on the button
                submitButton.click();
            }
        });
    }

    const conversationSearchButton = document.querySelector('.conversation-search-button-js');
    const conversationSearchSpinner = document.querySelector('.search-spinner-container-js');

    if (conversationSearchButton) {
        conversationSearchButton.addEventListener('click', async function (event) {
            event.preventDefault();

            const searchInput = document.querySelector('.conversation-search-query-js');
            const searchQuery = searchInput.value.trim();

            if (searchQuery.length <= 3) {
                // TODO add front-end error handling
                // alert('Search query must be more than 3 characters.');
                return;
            }

            conversationSearchSpinner.classList.toggle('show');

            try {
                const response = await fetch('/conversations/search-prior-conversations?search_query=' + encodeURIComponent(searchQuery), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                createConversations(data.prior_conversations);
                conversationSearchSpinner.classList.toggle('show');
            } catch (error) {
                console.error('Error:', error);
            }
        });
    }

    const priorConversationTrigger = document.querySelector('.prior-conversations-trigger-js');
    window.addEventListener('scroll', () => {
        if (window.scrollY > 1) {
            priorConversationTrigger.classList.add('fixed-conversation-trigger');
        } else {
            priorConversationTrigger.classList.remove('fixed-conversation-trigger');
        }
    });

    window.setTimeout(async () => {
        const loggedOutLink = document.querySelector('.logout-link-js');

        if (loggedOutLink) {
            await createConversations();
        }

        const tooltip = document.querySelector('.conversation-tooltip-js');

        // Hide the tooltip when clicking outside of it
        document.addEventListener('click', (event) => {
            if (!tooltip.contains(event.target)) {
                tooltip.classList.remove('show');
            }
        });

        // Stop propagation to prevent hiding when clicking inside the tooltip
        tooltip.addEventListener('click', (event) => {
            event.stopPropagation();
        });

        document.addEventListener('click', (event) => {
            const conversationOptionsElement = event.target.closest('.conversation-options-js');
            if (conversationOptionsElement) {
                event.stopPropagation();

                // Get the position of the clicked element
                const rect = conversationOptionsElement.getBoundingClientRect();

                if (window.innerWidth <= 1024) {
                    // Position the tooltip below the clicked element for mobile devices
                    tooltip.style.top = `${rect.bottom + window.scrollY}px`;
                    tooltip.style.right = '5%';
                    tooltip.style.left = ''; // Clear left position
                } else {
                    // Position the tooltip to the right of the clicked element for larger screens
                    tooltip.style.top = `${rect.top + window.scrollY}px`;
                    tooltip.style.left = `${rect.right + window.scrollX}px`;
                    tooltip.style.right = ''; // Clear right position
                }

                // Show the tooltip
                tooltip.classList.add('show');
                tooltip.setAttribute('data-conversation-id', conversationOptionsElement.closest('[data-conversation-id]').getAttribute('data-conversation-id'));
                document.querySelector('.conversation-link-parent-js').classList.remove('show');
            }
        });

        // INVITE TO CONVERSATION
        const inviteConversationElement = document.querySelector('.invite-conversation-js');
        const inviteBlockTwo = document.querySelector('.invite-block-two-js');

        inviteConversationElement.addEventListener('click', (event) => {
            event.stopPropagation();
            inviteConversationElement.classList.toggle('show');
            inviteBlockTwo.classList.toggle('show');
        });

        const conversationInviteInputElement = document.querySelector('.conversation-invite-emails-js');
        const conversationInviteSubmitButton = document.querySelector('.send-conversation-invite-button-js');

        conversationInviteInputElement.addEventListener('keydown', (event) => {
            // Check if the pressed key is 'Enter'
            if (event.key === 'Enter') {
                // If 'Shift' is also held down, just create a new line
                if (event.shiftKey) {
                    return;
                }

                // Otherwise, prevent default 'Enter' behavior (i.e., creating a new line and submit the form
                event.preventDefault();

                // Trigger a click on the button
                conversationInviteSubmitButton.click();
            }
        });

        conversationInviteSubmitButton.addEventListener('click', (event) => {
            // Prevent the default action
            event.preventDefault();

            // Get the endpoint arguments
            const settingValue = 'protected';
            const conversationId = tooltip.getAttribute('data-conversation-id');
            const emailsValueStr = conversationInviteInputElement.value;
            const emailsValue = emailsValueStr.split(',');

            // Define the endpoint URL
            const endpointUrl = '/conversations/update-settings';

            // Call the endpoint using fetch
            fetch(endpointUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    setting: settingValue,
                    conversation_id: conversationId,
                    emails_value: emailsValue
                })
            })
                .then(response => {
                    const successBlock = document.querySelector('.invite-block-three-js');
                    const errorBlock = document.querySelector('.invite-block-four-js');

                    if (!response.ok) {
                        inviteBlockTwo.classList.toggle('show');
                        errorBlock.classList.toggle('show');
                    } else {
                        inviteBlockTwo.classList.toggle('show');
                        successBlock.classList.toggle('show');
                    }

                    window.setTimeout(() => {
                        successBlock.classList.remove('show');
                        errorBlock.classList.remove('show');
                        inviteConversationElement.classList.add('show');
                    }, 1800);

                    return response.json();
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        });

        // GET PUBLIC LINK
        const shareElement = document.querySelector('.share-conversation-js');

        // Add a click event listener to the share-conversation-js element
        shareElement.addEventListener('click', function (event) {
            // Prevent the default action
            event.preventDefault();

            // Get the endpoint arguments
            const settingValue = 'public';
            const conversationId = tooltip.getAttribute('data-conversation-id');
            // const stack = document.querySelector('.subject-select-js').value;

            // Define the endpoint URL
            const endpointUrl = '/conversations/update-settings';

            // Call the endpoint using fetch
            fetch(endpointUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    setting: settingValue,
                    conversation_id: conversationId
                })
            })
                .then(async (response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok: ' + response.statusText);
                    }

                    const data = await response.json();

                    if (data.link) {
                        const conversationLinkParent = document.querySelector('.conversation-link-parent-js');
                        conversationLinkParent.innerHTML = `<span class="conversation-link-copy-js">Share this link: </span><a href="javascript:;" class="conversation-link-copy-js link-text-js">${data.link}</a> <img class="conversation-link-copy-js" width="28px" height="28px" src="/public/${version}/img/icons/copy.webp" />`;
                        conversationLinkParent.classList.add('show');
                    } else {
                        console.error('Link property not found in response');
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        });

        document.querySelector('.conversation-tooltip-js').addEventListener('click', (e) => {
            if (e.target.matches('.conversation-link-copy-js')) {
                const clickLink = e.target.closest('.conversation-link-parent-js');
                const linkElement = clickLink.querySelector('.link-text-js');
                const imgElement = clickLink.querySelector('img');
                const textElement = clickLink.querySelector('span');

                navigator.clipboard.writeText(linkElement.textContent);

                imgElement.setAttribute('src', `/public/${version}/img/icons/checkmark.webp`);
                textElement.textContent = 'Copied: ';

                window.setTimeout(() => {
                    imgElement.setAttribute('src', `/public/${version}/img/icons/copy.webp`);
                    textElement.textContent = 'Share this link: ';
                }, 1500);
            }
        });

        // DELETE CONVERSATION
        document.querySelector('.delete-conversation-js').addEventListener('click', (event) => {
            const conversationId = event.target.closest('.conversation-tooltip-js').getAttribute('data-conversation-id');
            deleteConversation(conversationId);
        });

        // GET PRIOR CONVERSATION FUNCTIONALITY
        let lastClickedConversationId = null;
        let lastClickTime = 0;
        const debounceTime = 1000; // 1 second debounce time

        document.body.addEventListener('click', async function (e) {
            const target = e.target;

            // Check if the clicked element or any of its parents match the specified classes
            if (target.matches('.prior-conversations__list-item') ||
                target.matches('.prior-conversation-js') ||
                target.matches('.prior-conversations__list--link-text')) {
                e.preventDefault();

                let conversationId = target.getAttribute('data-conversation-id');
                if (!conversationId) {
                    conversationId = target.closest('.prior-conversation-list-item-js').getAttribute('data-conversation-id');
                }

                const currentTime = Date.now();
                if (conversationId === lastClickedConversationId && currentTime - lastClickTime < debounceTime) {
                    // Ignore the click if it's within the debounce time for the same conversation
                    return;
                }

                lastClickedConversationId = conversationId;
                lastClickTime = currentTime;

                // Get the current URL
                const url = window.location.href;
                // Create a URL object
                const urlObj = new URL(url);
                // Get the query parameters
                const params = new URLSearchParams(urlObj.search);
                // Extract the accesskey
                const accessKey = params.get('accesskey');

                const conversationData = await fetchPriorConversation(conversationId, accessKey);

                newChat();
                createConversation(conversationData.prior_conversations);
            }
        });
    }, 100);
}
