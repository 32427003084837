import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';

function markdownToHtml (markdown) {
    return unified()
        .use(remarkParse) // Parse the Markdown
        .use(remarkRehype) // Convert to HTML
        .use(rehypeStringify) // Serialize the HTML
        .process(markdown)
        .then((file) => String(file));
}

export default markdownToHtml;
