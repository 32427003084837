export function bridgeHirePageJS () {
    document.querySelectorAll('.talk-to-expert-js').forEach((element) => {
        element.addEventListener('click', () => {
            window.location.href = '/talk-to-an-expert';
        });
    });

    // Select all elements with the class 'see-pricing-js'
    const pricingElements = document.querySelectorAll('.see-pricing-js');

    // Add a click event listener to each element
    pricingElements.forEach(function (element) {
        element.addEventListener('click', function () {
            // Navigate to the pricing page
            window.location.href = '/pricing';
        });
    });

    // PANEL 1
    const figures = document.querySelectorAll('.hire-panel-one-figure-js');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
            }
        });
    }, {
        threshold: 0.5 // Trigger when at least 10% of the element is visible
    });

    figures.forEach(figure => {
        observer.observe(figure);

        // Check if the figure is already in view on page load
        const rect = figure.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            figure.classList.add('show');
        }
    });

    // PANEL 2
    document.querySelector('.learn-more-custom-ai-js').addEventListener('click', () => {
        const learnMore = document.querySelector('.panel-two-learn-more-js');
        learnMore.classList.add('show');

        // Scroll to the element with an offset
        const headerOffset = 60; // Adjust this value based on your layout
        const elementPosition = learnMore.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    });

    // PANEL 3
    const contentSelectorsKey = {
        productivity: '.business-needs-productivity-js',
        revenue: '.business-needs-revenue-js',
        cost: '.business-needs-cost-js',
        liability: '.business-needs-liability-js',
        bugs: '.business-needs-bugs-js'
    };

    document.querySelector('.business-needs-select-js').addEventListener('change', (e) => {
        const selectedValue = e.target.value;
        e.target.classList.remove('animate');

        document.querySelectorAll('.business-needs-section-js').forEach((element) => {
            element.classList.remove('show');
        });

        if (contentSelectorsKey[selectedValue]) {
            const selector = contentSelectorsKey[selectedValue];
            document.querySelectorAll(selector).forEach((element) => {
                element.classList.add('show');
            });

            document.querySelector('.panel-three-content-js').classList.add('show');
            document.querySelector('.panel-three-footer-js').classList.add('show');
        }
    });

    // PANEL 4
    // Function to check if an element is in viewport
    function panelFourItemIsInViewport (element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // Function to add 'show' class to visible items and manage footer positioning
    function manageVisibleItemsAndFooter () {
        const listItems = document.querySelectorAll('.hire-bridgegpt__panel-four__content--list-item');
        const footer = document.querySelector('.panel-four-footer-js');
        const panelThree = document.querySelector('.business-needs-select-js');
        const thirdItem = listItems[2];
        // const lastItem = listItems[listItems.length - 1];
        const lastItem = document.querySelector('.panel-four-list-end-js');

        listItems.forEach(item => {
            if (panelFourItemIsInViewport(item)) {
                item.classList.add('show');
            }
        });

        // Check first item for footer positioning
        if (panelFourItemIsInViewport(thirdItem)) {
            footer.classList.add('position-fixed');
        }

        // Check last item to remove footer positioning
        if (panelFourItemIsInViewport(lastItem) || panelFourItemIsInViewport(panelThree)) {
            footer.classList.remove('position-fixed');
        }
    }

    // Add event listener for scroll
    window.addEventListener('scroll', manageVisibleItemsAndFooter);
}
