export async function refreshAccessToken (refreshToken) {
    const tokenMessage = {
        token: refreshToken
    };

    const response = await fetch('auth/refresh-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(tokenMessage)
    });

    if (response.ok) {
        const data = await response.json();
        return data.accessToken;
    } else {
        // Handle the case where the refresh token is invalid or expired
        return null;
    }
}

export async function getNewAccessToken () {
    const response = await fetch('auth/local-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (response.ok) {
        return await response.json();
    } else {
        return null;
    }
}

export function storeAccessToken (token) {
    const expirationTime = Date.now() + 14400 * 1000;
    window.localStorage.setItem('bridge_token', token);
    window.localStorage.setItem('bridge_token_expiration', expirationTime.toString());
}

export function storeRefreshToken (token) {
    const expirationTime = Date.now() + 604800 * 1000;
    window.localStorage.setItem('bridge_refresh_token', token);
    window.localStorage.setItem('bridge_refresh_token_expiration', expirationTime.toString());
}

export async function getToken () {
    const token = window.localStorage.getItem('bridge_token');
    const expirationTime = window.localStorage.getItem('bridge_token_expiration');
    const refreshToken = window.localStorage.getItem('bridge_refresh_token');
    const refreshExpirationTime = window.localStorage.getItem('bridge_refresh_token_expiration');
    if (token && expirationTime && Date.now() < parseInt(expirationTime, 10)) {
        return token;
    } else {
        if (refreshToken && refreshExpirationTime && Date.now() < parseInt(refreshExpirationTime, 10)) {
            const newToken = await refreshAccessToken(refreshToken);
            if (newToken) {
                storeAccessToken(newToken);
                return newToken;
            }
        } else {
            const newTokens = await getNewAccessToken();
            if (newTokens) {
                storeAccessToken(newTokens.accessToken);
                storeRefreshToken(newTokens.refreshToken);
                return newTokens.accessToken;
            }
        }

        return null;
    }
}
