export function signUpPageJS () {
    document.querySelector('.sign-up-form-js').addEventListener('submit', (e) => {
        e.preventDefault(); // Prevent the default form submission

        // Gather form data
        const form = document.querySelector('.sign-up-form-js');
        const name = document.querySelector('.sign-up-name-js').value;
        const email = document.querySelector('.sign-up-email-js').value;
        const inquiryType = document.querySelector('.sign-up-inquiry-type-js').value;

        const formData = {
            name,
            email,
            inquiryType
        };

        // Send the data using fetch
        fetch('/emails/sign-up', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(response => {
            if (response.ok) {
                form.style.display = 'none';
                const successBlock = document.querySelector('.sign-up__success-block');
                successBlock.style.opacity = 1;
                successBlock.style.minHeight = '300px';
                successBlock.style.maxHeight = '300px';
            } else {
                throw new Error('Request failed');
            }
        }).catch(() => {
            form.style.display = 'none';
            const errorBlock = document.querySelector('.sign-up__error-block');
            errorBlock.style.opacity = 1;
            errorBlock.style.minHeight = '300px';
            errorBlock.style.maxHeight = '300px';
        });
    });
}
